import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  TextAreaForm,
  SelectFormm,
  CheckboxFormMultiple,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  createAdmRoles,
  deleteAdminRole,
  getAdminRolesList,
  getAdminRolesListById,
  removeAdmDataCreateUID,
  removeAdmRoleId,
  updateAdminRoles,
} from "../../store/feature/adminRoles";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import {
  getAdminPageMasterList,
  removeDataAdmPageMaster,
} from "../../store/feature/adminPageMaster";
import adminPageAccess from "../../utils/validation/adminPageAccess";
import { getAdminUserList } from "../../store/feature/adminUser";
import {
  createAdmPageAccess,
  deleteAdminPageAccessRoleId,
  getAdminPageAccessListById,
  removeCreateAPASuccess,
  updateAdminPageAccess,
} from "../../store/feature/adminPageAccess";
import { useTheme } from "@mui/material/styles";
import {
  createAdmUserRoles,
  deleteAdminUserRoleByAdmRoleUID,
  getAdminUserRolesList,
  validateAdmUserRoles,
} from "../../store/feature/adminUserRoles";
import adminPageAccessEdit from "../../utils/validation/adminPageAccessEdit";
import AdminPageAccessTable from "./table/AdminPageAcc";
import {
  createEndpointException,
  deleteEndpointException,
  getEndpointExceptionList,
} from "../../store/feature/adminEndpointException";
import AdminPageAcc from "./table/AdminPageAcc";

const AdminPageAccessField = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [params] = useSearchParams();
  const idParam = params.get("adm-page-acces-uid");
  const [optAdmUser, setOptAdmUser] = useState([]);
  const [dataPageAccess, setDataPageAccess] = useState<any>([]);
  const [dataAdmUsrRoles, setDataAdmUsrRoles] = useState<any>([]);
  const [createAdmUsrRoles, setCreateAdmUsrRoles] = useState<any>({});
  const [editAdmUsrRoles, setEditAdmUsrRoles] = useState<any>({});
  const [pageAccessHit, setPageAccessHit] = useState<any>(false);
  const [optionAdmUser, setOptionAdmUser] = useState<any>({});
  const [open, setOpen] = useState<any>(false);
  interface CheckBoxItem {
    pge_master_uid: string;
    // Add other properties as needed
  }
  const [checkBoxMulti, setCheckBoxMulti] = useState<CheckBoxItem[]>([]);
  const {
    admRoleId,
    admDataCreateUID,
    admDataUpdateUID,
    isLoadingAdmRoles,
    isLoadingEvent,
  } = useAppSelector((root: RootState) => root.admRoles);
  const { dataAdmPageMaster } = useAppSelector(
    (root: RootState) => root.admPageMstr
  );
  const { dataAdmUser } = useAppSelector((root: RootState) => root.admUser);
  const { dataAdmUserRoles, validateStatus } = useAppSelector(
    (root: RootState) => root.admUserRoles
  );
  const { admPageAccessId, createAPASuccess, deleteAPASuccess } =
    useAppSelector((root: RootState) => root.admPageAcs);

  const { admPageAccess } = useAppSelector((root: RootState) => root.user);
  // Convert the JSON array into an object
  const jsonFieldType: Record<string, any> = {};
  const jsonType = {};

  for (const item of checkBoxMulti) {
    jsonFieldType[item?.pge_master_uid] = [];
  }

  // console.log("checkBoxMulti ===> ", JSON.stringify(checkBoxMulti));
  // console.log("jsonFieldType ===> ", JSON.stringify(jsonFieldType));
  const isEdit = pathname.includes("edit");

  interface jsonData extends Record<string, any> {
    accessGroupName: string;
    accessRemark: string;
    roleFor: string;
    roleRelateTo: any;
    accessChecklist: any;
    roleRelated: any;
    id: string;
    status: string;
  }

  // Merge the properties from jsonObject into jsonData
  type MergedData = jsonData & typeof jsonFieldType;

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<MergedData>({
    reValidateMode: "onChange",
    resolver: yupResolver(isEdit ? adminPageAccessEdit : adminPageAccess),
    defaultValues: {
      accessGroupName: "",
      accessRemark: "",
      roleFor: "",
      roleRelateTo: [],
      accessChecklist: [],
      roleRelated: [],
      id: "",
      status: "",
      adm_pge_master: [],
      adm_pge_masters: [],
      ...jsonFieldType,
    },
  });

  //get all

  useEffect(() => {
    if (Object.keys(admPageAccess).length !== 0) {
      // dispatch(getAdminPageMasterList({ web_rights: "admin", pagesize: 1000 }));
      dispatch(removeAdmDataCreateUID());
      dispatch(removeCreateAPASuccess());
      dispatch(getAdminUserList({ pagesize: 1000 }));
      dispatch(removeDataAdmPageMaster());
      console.log("from useeffect multi ==> ", checkBoxMulti);
      if (isEdit) {
        // console.log("ada gak sih bor ===> ");
        dispatch(removeAdmRoleId());
        dispatch(getAdminPageAccessListById(idParam));
        dispatch(getAdminRolesListById(idParam));
        dispatch(getAdminUserRolesList({ uc_adm_role_uid: idParam }));
      } else {
        dispatch(removeDataAdmPageMaster());
        setCheckBoxMulti([]);
      }
    }
    console.log("from useeffect multi ==> ", checkBoxMulti);
  }, [admPageAccess]);

  const onSubmit = async (e: any) => {
    console.log("submit hit ===> ");
    // data for create admin roles
    const roleRelatedTo = e.roleRelateTo
      .map((item: any) => item.value)
      .join(";");
    const newEditAdmRoles = {
      uc_adm_role_uid: idParam,
      uc_adm_role_active: e.status,
      uc_adm_role_desc: e.accessRemark,
      uc_adm_role_title: e.accessGroupName,
      uc_adm_role_related: roleRelatedTo,
    };
    const newCreateAdmRoles = [
      {
        uc_adm_role_active: e.status,
        uc_adm_role_desc: e.accessRemark,
        uc_adm_role_title: e.accessGroupName,
        uc_adm_role_related: roleRelatedTo,
      },
    ];

    setEditAdmUsrRoles(newEditAdmRoles);
    setCreateAdmUsrRoles(newCreateAdmRoles);

    let result: any = {};

    for (const keyObj of checkBoxMulti) {
      const key = keyObj["pge_master_uid"];
      if (e.hasOwnProperty(key) && e[key]?.length > 0) {
        result[key] = e[key];
      }
    }

    const transformedData: string | any[] = [];

    for (const roleId in result) {
      const permissions = result[roleId];
      const transformedObject = {
        pge_master_uid: roleId,
        uc_adm_pge_access_status: e.status,
        uc_adm_role_uid: idParam,
        uc_adm_pge_acs_create: permissions?.includes("CREATE") ? 1 : 0,
        uc_adm_pge_acs_delete: permissions?.includes("DELETE") ? 1 : 0,
        uc_adm_pge_acs_read: permissions?.includes("READ") ? 1 : 0,
        uc_adm_pge_acs_update: permissions?.includes("UPDATE") ? 1 : 0,
      };
      transformedData.push(transformedObject);
    }

    console.log("transformData ===> ", JSON.stringify(transformedData));
    console.log("admPageAccessId ===> ", JSON.stringify(admPageAccessId));

    // Fungsi untuk memeriksa keberadaan data dalam array
    function isDataInArray(
      data: string | any[],
      target: {
        pge_master_uid: any;
        uc_adm_pge_access_status: any;
        uc_adm_role_uid: any;
        uc_adm_pge_acs_create: any;
        uc_adm_pge_acs_delete: any;
        uc_adm_pge_acs_read: any;
        uc_adm_pge_acs_update: any;
      }
    ) {
      for (var i = 0; i < data.length; i++) {
        if (
          data[i].pge_master_uid === target.pge_master_uid &&
          data[i].uc_adm_pge_access_status ===
            target.uc_adm_pge_access_status &&
          data[i].uc_adm_role_uid === target.uc_adm_role_uid &&
          data[i].uc_adm_pge_acs_create === target.uc_adm_pge_acs_create &&
          data[i].uc_adm_pge_acs_delete === target.uc_adm_pge_acs_delete &&
          data[i].uc_adm_pge_acs_read === target.uc_adm_pge_acs_read &&
          data[i].uc_adm_pge_acs_update === target.uc_adm_pge_acs_update
        ) {
          return true;
        }
      }
      return false;
    }

    let count = 0;
    admPageAccessId.forEach(function (item: {
      pge_master_uid: any;
      uc_adm_pge_access_status: any;
      uc_adm_role_uid: any;
      uc_adm_pge_acs_create: any;
      uc_adm_pge_acs_delete: any;
      uc_adm_pge_acs_read: any;
      uc_adm_pge_acs_update: any;
    }) {
      if (isDataInArray(transformedData, item)) {
        count++;
        console.log("Data ditemukan di dalam array kedua:", item);
      } else {
        console.log("Data tidak ditemukan di dalam array kedua:", item);
      }
    });
    setPageAccessHit(count === admPageAccessId.length);

    // set value for create admin page access
    setDataPageAccess(transformedData);

    // console.log("e ==========> ", roleRelatedTo);
    // set value for create admin user role
    const transformData = e.roleRelated.map((item: any) => {
      return {
        uc_adm_role_status: e.status,
        uc_adm_user_uid: item.value,
      };
    });
    setDataAdmUsrRoles(transformData);

    console.log("hahaha bisa nih ===> ", e.roleFor);
    console.log("hahaha bisa nih ===> ", JSON.stringify(e.roleRelated));
    const validateForm = {
      pge_master_web_rights: e.roleFor,
      uc_adm_user_uid: e.roleRelated.map((item: any) => item.value),
    };

    if (isEdit) {
      const valuesArray1 = e.roleRelated?.map((item: any) => item.value);
      const valuesArray2 = optionAdmUser?.map((item: any) => item.value);
      const valuesInBothArrays = valuesArray1.every((value: any) =>
        valuesArray2.includes(value)
      );

      // Find values in valuesArray1 that are not in valuesArray2
      const valuesNotInArray2 = valuesArray1.filter(
        (value: any) => !valuesArray2.includes(value)
      );
      const valuesNotInArray2Form = {
        pge_master_web_rights: e.roleFor,
        uc_adm_user_uid: valuesNotInArray2,
      };
      if (valuesInBothArrays) {
        dispatch(updateAdminRoles(newEditAdmRoles));
      } else {
        await dispatch(validateAdmUserRoles(valuesNotInArray2Form));
      }
    } else {
      await dispatch(validateAdmUserRoles(validateForm));
    }
  };

  useEffect(() => {
    if (!validateStatus.value && !isEdit && createAdmUsrRoles.length > 0) {
      dispatch(createAdmRoles(createAdmUsrRoles));
    } else if (
      !validateStatus.value &&
      isEdit &&
      Object.keys(editAdmUsrRoles).length > 0
    ) {
      dispatch(updateAdminRoles(editAdmUsrRoles));
    }
  }, [validateStatus]);

  // Create
  // step 2 to create admin page access
  useEffect(() => {
    console.log(
      "from up useeffect step admDataUpdateUID ====> ",
      admDataUpdateUID
    );
    if (isEdit) {
      if (admDataUpdateUID.length !== 0) {
        dataPageAccess.forEach((item: any) => {
          item.uc_adm_role_uid = admDataUpdateUID;
        });
        (async () => {
          try {
            // TODO delete endpoint exception
            if (!pageAccessHit) {
              const pageAccessUId = admPageAccessId.map(
                (item: any) => item.uc_adm_pge_access_uid
              );
              await dispatch(
                deleteEndpointException({
                  uc_adm_pge_access_uid: pageAccessUId,
                })
              );
              await dispatch(deleteAdminPageAccessRoleId(idParam));
              if (dataPageAccess.length > 0) {
                dispatch(createAdmPageAccess(dataPageAccess));
              }
            } else {
              navigate("/admin-page-access");
              Swal.fire("Success!", "Success Update Admin Page Access", "success");
            }
            // dispatch(removeAdmDataCreateUID());
          } catch (error) {
            // Handle any errors that occurred during deletion
            console.log(
              "error from catch create admin page access ===> ",
              error
            );
          }
        })();
      }
    } else {
      console.log("admDataCreateUID ===> ", admDataCreateUID);
      if (admDataCreateUID.length !== 0) {
        console.log("wkwkwk ====> ", admDataCreateUID);
        if (dataPageAccess.length > 0) {
          dataPageAccess.forEach((item: any) => {
            item.uc_adm_role_uid = admDataCreateUID;
          });
          dispatch(createAdmPageAccess(dataPageAccess));
          createAdminUserRolesF();
        } else {
          console.log("masuk ke else ==> ");
          createAdminUserRolesF();
        }
      }
    }
  }, [admDataCreateUID, admDataUpdateUID]);

  // Create
  // step 3 to create admin user roles
  // execute when update data
  useEffect(() => {
    if (Object.keys(deleteAPASuccess).length !== 0) {
      createAdminUserRolesF();
    }
  }, [deleteAPASuccess]);

  const createAdminUserRolesF = () => {
    if (isEdit) {
      console.log("from isEdit ===> ", isEdit);
      // if (Object.keys(createAPASuccess).length !== 0) {
      console.log("your welcome brother ===> 1", createAPASuccess);
      dataAdmUsrRoles.forEach((item: any) => {
        item.uc_adm_role_uid = idParam;
      });
      (async () => {
        try {
          await dispatch(deleteAdminUserRoleByAdmRoleUID(idParam));
          dispatch(createAdmUserRoles(dataAdmUsrRoles));
          dispatch(removeCreateAPASuccess());
          navigate("/admin-page-access");
          Swal.fire("Success!", "Success Update Admin Page Access", "success");
        } catch (error) {
          // Handle any errors that occurred during deletion
          console.log("error from catch create admin page access ===> ", error);
        }
      })();
      // }
    } else {
      // if (Object.keys(createAPASuccess).length !== 0) {
      if (Object.keys(admDataCreateUID).length !== 0) {
        dataAdmUsrRoles.forEach((item: any) => {
          item.uc_adm_role_uid = admDataCreateUID;
        });
        // console.log("hahah here ===> ", createAPASuccess);
        dispatch(createAdmUserRoles(dataAdmUsrRoles));
        dispatch(removeAdmDataCreateUID());
        navigate("/admin-page-access");
        Swal.fire("Success!", "Success Create Admin Page Access", "success");
      }
    }
  };

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];
  // "ADMIN;SYS_CONFIG;CA;KMS;VA"
  const optRoleFor = [
    {
      label: "Admin",
      value: "ADMIN",
    },
    {
      label: "System Config",
      value: "SYS_CONFIG",
    },
    {
      label: "CA",
      value: "CA",
    },
    {
      label: "KMS",
      value: "KMS",
    },
    {
      label: "VA",
      value: "VA",
    },
  ];

  const optAccessChecklis = [
    {
      label: "Create",
      value: "CREATE",
    },
    {
      label: "Read",
      value: "READ",
    },
    {
      label: "Update",
      value: "UPDATE",
    },
    {
      label: "Delete",
      value: "DELETE",
    },
  ];

  // for get admin user option
  useEffect(() => {
    let optAdmTemp = dataAdmUser.map((item: any) => ({
      label: item.uc_adm_user_email,
      value: item.uc_adm_user_uid,
    }));
    setOptAdmUser(optAdmTemp);
  }, [dataAdmUser]);

  // reset on edit from admin role
  useEffect(() => {
    if (isEdit) {
      setValue("accessGroupName", admRoleId?.uc_adm_role_title);
      setValue("accessRemark", admRoleId?.uc_adm_role_desc);
      setValue("status", admRoleId?.uc_adm_role_active?.toUpperCase());
      setValue(
        "roleRelateTo",
        admRoleId?.uc_adm_role_related?.split(";").map((item: any) => {
          let label = item.replace(/_/g, " ");
          if (item === "ADMIN") {
            label = "Admin";
          } else if (item === "SYS_CONFIG") {
            label = "Sys Config";
          }
          return {
            label,
            value: item,
          };
        })
      );
    } else {
      reset();
    }
  }, [admRoleId]);

  const handleAPIEndpoint = (e: any) => {
    if (e.uc_adm_pge_access_uid !== undefined) {
      dispatch(getEndpointExceptionList(e.uc_adm_pge_access_uid));
      setOpen(true);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Cannot input API Exception right now, API Exception data can inputted after submit the new page access data first",
      });
      setOpen(false);
    }
  };

  // set value for checkbox multi for edit role
  useEffect(() => {
    if (isEdit) {
      console.log("uc_adm_pge_access_uid ==> ", admPageAccessId);
      let tempArr = admPageAccessId.map((item: any) => ({
        pge_master_uid: item.pge_master_uid,
        pge_master_name: item.pge_master_name,
        uc_adm_pge_access_uid: item.uc_adm_pge_access_uid,
      }));
      setCheckBoxMulti(tempArr);
      console.log("tempArr ==> ", tempArr);
      admPageAccessId.forEach((data: any) => {
        const pge_master_uid = data.pge_master_uid;
        const access = [];
        if (data.uc_adm_pge_acs_read === 1) access.push("READ");
        if (data.uc_adm_pge_acs_create === 1) access.push("CREATE");
        if (data.uc_adm_pge_acs_update === 1) access.push("UPDATE");
        if (data.uc_adm_pge_acs_delete === 1) access.push("DELETE");
        setValue(pge_master_uid, access);
        console.log("hahaha ===> ", pge_master_uid, access);
      });
    }
  }, [admPageAccessId]);

  // set value for API module
  useEffect(() => {
    if (isEdit) {
      const result = optAdmUser.filter((item: any) => {
        return dataAdmUserRoles.some(
          (firstItem: any) => firstItem.uc_adm_user_uid === item.value
        );
      });
      setOptionAdmUser(result);
      setValue("roleRelated", result);
    }
  }, [dataAdmUserRoles, optAdmUser]);

  // run when handleRole for hit
  useEffect(() => {
    if (dataAdmPageMaster.length !== 0) {
      // Create a map for faster lookup
      const secondMap = admPageAccessId.reduce(
        (
          map: { [x: string]: any },
          obj: { pge_master_uid: string | number; uc_adm_pge_access_uid: any }
        ) => {
          map[obj.pge_master_uid] = obj.uc_adm_pge_access_uid;
          return map;
        },
        {}
      );

      // Update the first array with uc_adm_pge_access_uid
      const resultArray = dataAdmPageMaster.map(
        (obj: { pge_master_uid: string | number }) => {
          const uc_adm_pge_access_uid = secondMap[obj.pge_master_uid];
          return { ...obj, uc_adm_pge_access_uid };
        }
      );

      console.log("dataAdmPageMaster ==> ", dataAdmPageMaster);
      console.log("admPageAccessId ==> ", admPageAccessId);
      console.log("dataPageAccess ==> ", dataPageAccess);
      console.log("resultArray ===> ", resultArray);

      setCheckBoxMulti(resultArray);
    }
  }, [dataAdmPageMaster]);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          return dispatch(deleteAdminRole(idParam));
        }
      })
      .then(() => {
        return dispatch(deleteAdminPageAccessRoleId(idParam));
      })
      .then(() => {
        dispatch(deleteAdminUserRoleByAdmRoleUID(idParam));
      })
      .then(() => {
        navigate("/admin-page-access");
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: palette.primary.main,
          text: "Your file has been deleted",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        });
      });
  };

  const handleRoleFor = (e: any) => {
    dispatch(
      getAdminPageMasterList({
        web_rights: e.value,
        pagesize: 1000,
        status: "active",
      })
    );
  };

  // function for disable checkbox for read
  const handleMultiplleForm = (e: any) => {
    if (e.checked) {
      let updatedArray = ["READ"];

      if (e.value !== "READ") {
        updatedArray.push(e.value);
      }

      if (e.fieldOpt?.value && Array.isArray(e.fieldOpt.value)) {
        updatedArray = updatedArray.concat(
          e.fieldOpt.value.filter((item: any) => item !== e.value)
        );
      }
      let filteredArr = updatedArray.filter(
        (item, index) => item !== "READ" || updatedArray.indexOf(item) === index
      );
      setValue(e.fieldOpt.name, filteredArr);
    } else if (e.value === "READ" && !e.checked) {
      setValue(e.fieldOpt.name, []);
    }
  };

  const roleForLabel = isEdit
    ? "Role For (please choose the role base on current list menu)"
    : "Role For";
  return (
    <>
      <DetailDataSkeleton
        isLoading={isLoadingAdmRoles}
        isLoadingEvent={isLoadingEvent}
        titleWidth={"300"}
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="25px">
          <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
            {isEdit ? "Update Admin Page Access" : "Create Admin Page Access"}
          </Typography>
          <InputForm
            name="accessGroupName"
            label="Access Group Name"
            errors={errors}
            control={control}
            required
            placeholder="Type Access Group Name Here....."
            maxLength={100}
            isLoading={false}
          />
          <TextAreaForm
            name="accessRemark"
            label="Description"
            errors={errors}
            control={control}
            placeholder="Type Description Here....."
            maxLength={100}
            isLoading={false}
            defaultValue={""}
          />
          <SelectFormm
            name="roleRelateTo"
            label="Role Related to(API)"
            defaultValue={"ADMIN"}
            options={optRoleFor}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
            isMulti
          />
          <SelectFormm
            name="roleFor"
            label={roleForLabel}
            defaultValue={"ADMIN"}
            options={optRoleFor}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
            onChangeForm={handleRoleFor}
          />
          {checkBoxMulti?.length !== 0 && (
            <Paper
              sx={{
                marginY: "20px",
                paddingX: "70px",
                paddingY: "20px",
                maxHeight: "30hv",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              {checkBoxMulti?.map((item: any) => {
                return (
                  <>
                    <Grid
                      container
                      spacing={5}
                      sx={{ marginTop: "30px" }}
                      alignItems={"center"}
                    >
                      <Grid item xs={10}>
                        <CheckboxFormMultiple
                          key={item.pge_master_uid}
                          name={item.pge_master_uid}
                          label={item.pge_master_name}
                          options={optAccessChecklis}
                          disabled={false}
                          control={control}
                          errors={errors}
                          defaultValue
                          required
                          onChange={handleMultiplleForm}
                        />
                      </Grid>
                      <Grid item xs={2} justifyContent={"flex-start"}>
                        {isEdit && (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleAPIEndpoint(item)}
                          >
                            Set Endpoint Exception
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Paper>
          )}
          <SelectFormm
            name="roleRelated"
            label="User Members"
            defaultValue={1}
            options={optAdmUser}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
            isMulti
          />
          <SelectFormm
            name="status"
            label="Status"
            defaultValue={1}
            options={optStatusSelect}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
          />

          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {isEdit && (
              <Button variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              color="gray"
              onClick={() => navigate("/admin-page-access")}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              {isEdit ? "Update" : "Submit"}
            </Button>
          </Stack>
        </Box>
      </DetailDataSkeleton>
      <AdminPageAcc open={open} setOpen={setOpen} />
    </>
  );
};

export default AdminPageAccessField;
