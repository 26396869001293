import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";

interface IFormGroup {
  isLoadingAdmRoles: boolean;
  isLoadingEvent: boolean;
  dataAdmRoles: any;
  totalAdmRoles: number;
  admRoleId: any;
  admDataCreateUID: string;
  admDataUpdateUID: string;
}

const initialState: IFormGroup = {
  isLoadingAdmRoles: false,
  isLoadingEvent: false,
  dataAdmRoles: [],
  totalAdmRoles: 0,
  admRoleId: [],
  admDataCreateUID: "",
  admDataUpdateUID: "",
};

export const getAdminRolesList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminRoles/get-list", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.admUser.uc_adm_pge_access_uid;
    const resp: any = await instance.get(`/admin-roles`, {
      params: data,
      headers: {
        'page-access-uid': uid,
      },
    });
    const dataPayload = resp.data.data;
    console.log("data admin roles users  ========> ", dataPayload);
    const dataAdmRoleList = dataPayload !== null ? dataPayload.data : [];
    console.log("dataAdmRoleList  ===> ", dataAdmRoleList);
    const total = dataPayload !== null ? dataPayload.total_records : 0;
    return { dataAdmRoleList, total };
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getAdminRolesListById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminRoles/get-list-by-id", async (id, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    console.log("in on admin user roles ==> ", state?.user);
    const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
    const resp: any = await instance.get(`/admin-role/${id}`, {
      headers: {
        'page-access-uid': uid,
      },
    });
    // console.log("data admin roles users  ========> ", resp.data.data.data);
    const dataPayload = resp.data.data;
    return dataPayload;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createAdmRoles = createAsyncThunk<any, any, { rejectValue: any }>(
  "adminRoles/create",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      console.log("in on admin user roles ==> ", state?.user);
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp: any = await instance.post("/admin-roles", data, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // Swal.fire({
      //   icon: "success",
      //   title: resp.data.msg,
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
      // dispatch(getAdminRolesList({ pagesize: 10 }));
      const temp = resp?.data.data[0];

      console.log("live live on ===> ", resp);
      return temp?.uc_adm_role_uid;
    } catch (err) {
      console.log("error", err);
      Swal.fire("Error !", "Error for create Admin Roles", "error");
      throw rejectWithValue(err);
    }
  }
);

export const updateAdminRoles = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminRoles/update", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    console.log("in on admin user roles ==> ", state?.user);
    const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
    const resp = await instance.put(
      `/admin-role/${data.uc_adm_role_uid}`,
      data,
      {
        headers: {
          'page-access-uid': uid,
        },
      }
    );
    console.log(" result from create ===> ", resp.data);
    // Swal.fire({
    //   icon: "success",
    //   title: resp.data.msg,
    //   showConfirmButton: false,
    //   timer: 1500,
    // });
    return resp?.data.data.uc_adm_role_uid;
  } catch (err) {
    Swal.fire("Error !", "Error for Update Admin Roles", "error");
    throw rejectWithValue(err);
  }
});

export const deleteAdminRole = createAsyncThunk<any, any, { rejectValue: any }>(
  "adminRole/delete",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      console.log("in on admin user roles ==> ", state?.user);
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp = await instance.delete(`/admin-role/${data}`, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // dispatch(getAdminRolesList({pagesize: 10}));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const adminRoles = createSlice({
  name: "adminRole",
  initialState,
  reducers: {
    removeAdmRoleId: (state) => {
      state.admRoleId = {};
    },
    removeAdmDataCreateUID: (state) => {
      state.admDataCreateUID = "";
    },
    removeAdmDataUpdateUID: (state) => {
      state.admDataUpdateUID = "";
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getAdminRolesList.pending, (state) => {
        state.isLoadingAdmRoles = true;
      })
      .addCase(getAdminRolesList.fulfilled, (state, action) => {
        state.isLoadingAdmRoles = false;
        const { dataAdmRoleList, total } = action.payload;
        console.log("dataAdmRoleList ===> ", dataAdmRoleList);
        state.dataAdmRoles = dataAdmRoleList;
        state.totalAdmRoles = total;
      })
      .addCase(getAdminRolesList.rejected, (state) => {
        state.isLoadingAdmRoles = false;
      })

      .addCase(getAdminRolesListById.pending, (state) => {
        state.isLoadingAdmRoles = true;
      })
      .addCase(getAdminRolesListById.fulfilled, (state, action) => {
        state.isLoadingAdmRoles = false;
        state.admRoleId = action.payload;
      })
      .addCase(getAdminRolesListById.rejected, (state) => {
        state.isLoadingAdmRoles = false;
      })

      .addCase(createAdmRoles.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createAdmRoles.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        state.admDataCreateUID = action.payload;
      })
      .addCase(createAdmRoles.rejected, (state) => {
        state.isLoadingEvent = false;
      })

      .addCase(updateAdminRoles.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateAdminRoles.fulfilled, (state, action) => {
        console.log("action,payload ===> ", action.payload);
        state.admDataUpdateUID = action.payload;
        state.isLoadingEvent = false;
      })
      .addCase(updateAdminRoles.rejected, (state) => {
        state.isLoadingEvent = false;
      })

      .addCase(deleteAdminRole.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(deleteAdminRole.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(deleteAdminRole.rejected, (state) => {
        state.isLoadingEvent = false;
      });
  },
});

export const {
  removeAdmRoleId,
  removeAdmDataCreateUID,
  removeAdmDataUpdateUID,
} = adminRoles.actions;
export default adminRoles.reducer;
